import Questions from "../questions/Questions";
import React, { useEffect, useState } from 'react';
import RegistrationService from '../../../../services/RegistrationService';
import TokenNotFound from "./TokenNotFound";
import TokenExpired from "./TokenExpired";
import TokenOK from "./TokenOK";

export default function ConfirmRegister() {

    const [enable, setEnable] = useState("");

    useEffect(() => {
        console.log('i fire once');
        confirm();
    }, []);

    const confirm = () => {

        RegistrationService.confirm()
            .then((response) => {
                setEnable(200);
            }).catch((error) => {
                setEnable(error.response.status);
            });
    };

    return (
        <div id="wrapper">
            <div className="content">
                <section className="hero-section-light">
                    {enable  === 200 && <TokenOK />}
                    {enable  === 401 && <TokenExpired />}
                    {enable  === 404 && <TokenNotFound />}
                </section>
                <Questions />
            </div>
        </div>
	);
}