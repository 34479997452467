import "./ScrollTop.css";
import { useEffect, useState } from "react";

export default function ScrollTop() {
  const [showButton, setShowButton] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 300) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    });
  }, []);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // for smoothly scrolling
    });
  };
  
  return ( 
	  <a onClick={scrollToTop} className={`to-top ${showButton && 'active'}`}><i className ="fa fa-angle-up"></i></a>
  );
}