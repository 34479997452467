import logo from '../images/logo_moltoutile_dark.png';
import { useState } from 'react'
import "./Header.css";
import "./HeaderMobile.css";
import { NavLink  } from 'react-router-dom'; 
import LoginForm from "../loginform/LoginForm";

export default function Header() {
  const [showNavbar, setShowNavbar] = useState(false)
  const handleShowNavbar = () => {setShowNavbar(!showNavbar)}
  const [components, setComponents] = useState(["Sample Component"]); 
  const handleShow = () => {setComponents([...components, "Sample Component"]) }
  
  return (
            <header className ="main-header white-header fs-header sticky">
                <div className ="header-inner">
                    <div className ="logo-holder">
                        <a href="/" rel="noopener noreferrer"> <img src={logo} alt="moltoutile.com" /></a>
                    </div>
                    <div className ="show-reg-form modal-open"  onClick={handleShow}><i className ="fa fa-sign-in"></i>Login 
                    	<LoginForm text="disable"/> 
                    </div>
                    <div className="menu-icon" onClick={handleShowNavbar}>
                    	<div className ="nav-button-wrap color-bg">
                        	<div className ="nav-button">
                            	<span></span><span></span><span></span>
                        	</div>
                    	</div>
                    </div>
                    <div className={`nav-holder main-menu-mobile ${showNavbar && 'active'}`}>
                        <nav>
                            <ul>
                                <li>
                                     <NavLink  onClick={handleShowNavbar} to="/" className={({ isActive, isPending }) => isPending ? "pending" : isActive ? "act-link" : ""}>  Home  </NavLink> 
                                </li>
                                <li>
                                     <NavLink  onClick={handleShowNavbar} to="/contacts" className={({ isActive, isPending }) => isPending ? "pending" : isActive ? "act-link" : ""}>  Contatti  </NavLink> 
                                </li>                                    
                                <li>
                                     <NavLink  onClick={handleShowNavbar} to="/registration" className={({ isActive, isPending }) => isPending ? "pending" : isActive ? "act-link" : ""}>  Registrazione  </NavLink> 
                                </li>                                                            
                            </ul>
                        </nav>				                       
                    </div>
                </div>
            </header>
  );
}