import logo from '../images/logo_moltoutile_light.png';

export default function Copyright() {
  return (
            <div className ="sub-footer fl-wrap">
                <div className ="container">
                    <div className ="row">
                        <div className ="col-md-4">
                            <div className ="about-widget">
                                <img src={logo} alt="moltoutile.com"/>
                            </div>
                        </div>
                        <div className ="col-md-4">
                            <div className ="copyright"> &#169; Skillati SW {new Date().getFullYear()} .  All rights reserved.</div>
                        </div>
                        <div className ="col-md-4">
                            <div className ="footer-social">
                                <ul>
                                    <li><a href="#" target="_blank" ><i className ="fa fa-facebook-official"></i></a></li>
                                    <li><a href="#" target="_blank"><i className ="fa fa-twitter"></i></a></li>
                                    <li><a href="#" target="_blank" ><i className ="fa fa-chrome"></i></a></li>
                                    <li><a href="#" target="_blank" ><i className ="fa fa-vk"></i></a></li>
                                    <li><a href="#" target="_blank" ><i className ="fa fa-whatsapp"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
  );
}