import "./Footer.css";
import "./FooterMobile.css";
import Copyright from "./Copyright";

export default function Footer() {
  return (
        <footer className ="main-footer dark-footer">
           <Copyright />
        </footer>
  );
}