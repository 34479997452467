import "./LoginForm.css";
import { useState } from 'react'

export default function LoginForm(props) {
	const [isOpen, setIsOpen] = useState(false);
	const handleShowLogin = () => {setIsOpen("disable")}
	return ( 
        <div className={`main-register-wrap modal ${isOpen} ${props.text}`}>
            <div className ="main-register-holder">
                <div className ="main-register fl-wrap">
                    <div className ="close-reg" onClick={handleShowLogin}><i className ="fa fa-times"></i></div>
                    <h3>Accedi a <span>Molto<strong>Utile.com</strong></span></h3>
                    <div id="tabs-container">
                        <ul className ="tabs-menu">
                            <li className ="current"><a href="#tab-1">Login</a></li>
                        </ul>
                        <div className ="tab">
                            <div id="tab-1" className ="tab-content">
                                <div className ="custom-form">
                                    <form method="post"  name="registerform">
                                        <label>Email *</label>
                                        <input name="email" type="text"/>
                                        <label >Password * </label>
                                        <input name="password" type="password"/>
                                        <button type="submit"  className ="log-submit-btn"><span>Entra</span></button>
                                        <div className ="clearfix"></div>
                                    </form>
                                    <div className ="lost_password">
                                        <a href="#">Lost Your Password?</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
  	);
}