import "./Registration.css";
import Questions from "../questions/Questions";
import Faq from "../faq/FaqRegistration";
import React, { useState } from 'react'
import RegistrationService from '../../../../services/RegistrationService';
import { useForm } from "react-hook-form";

export default function Registration() {
    const { handleSubmit, register, formState: { errors } } = useForm();
	const [msg, setMsg] = useState("");
	const [errorMsg, setErrorMsg] = useState(false);
	const RegisterUser = (data) => {
		console.log(data);
        RegistrationService.save(data)
            .then((res) => {
                console.log("User Added Successfully");
                setMsg("Inviata email di conferma registrazione, grazie!");
            }).catch((error) => {
                setMsg(error.response.data);
                setErrorMsg(true);
                console.log(error);
            });
	}
	return (
		<div id="wrapper">
			<div className="content">
				<section className="hero-section-light">
					<div className="container">
						<div className="row">
							<div className="list-single-main-wrapper fl-wrap col-md-8">
								<div className="section-title bot25padding">
									<h2>Registrazione</h2>
									<span className="section-separator"></span>
								</div>
								<div id="contact-form">
									{msg && <div className={`message ${errorMsg ? "error" : ""}`}>{msg}</div>}
									<form className="custom-form" onSubmit={handleSubmit(RegisterUser)}>
										<fieldset>
											<div className="col-md-6 col-xs-6">
												<label className={`label-radio ${errors.type ? "error" : ""}`} >Amministratore
													<input type="radio" value="3"
                                                        {...register("type", {
                                                            required: "Dato richiesto!"
                                                        })}
													/>
													<span className="checkmark"></span>
												</label>
											</div>
											<div className="col-md-6 col-xs-6">
												<label className={`label-radio ${errors.type ? "error" : ""}`}>Azienda
                                                    <input className={`${errors.type ? "error" : ""}`} type="radio" value="4"
                                                        {...register("type", {
                                                            required: "Dato richiesto!"
                                                        })}
													/>
													<span className="checkmark"></span>
												</label>
											</div>
											<div className="col-md-6">
												<label><i className="fa fa-user-o"></i></label>
												<input className={`${errors.name ? "error" : ""}`}
											        type="text"
												    placeholder={`${errors.name ? errors.name.message : "Nome Completo *"}`}
                                                    {...register("name", {
                                                        required: "Nome Completo richiesto!"
                                                    })}
												 />
											</div>
											<div className="col-md-6">
											    <label><i className="fa fa-envelope-o"></i> </label>
												<input className={`${errors.email ? "error" : ""}`}
											        type="text"
												    placeholder={`${errors.email ? errors.email.message : "Email *"}`}
                                                    {...register("email", {
                                                        required: "Email richiesta!",
                                                        pattern: {
                                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                            message: "Email non valida!"
                                                        }
                                                    })}
												 />
											</div>
											<div className="col-md-6">
												<label><i className="fa fa-address-card-o"></i></label>
												<input className={`${errors.piva ? "error" : ""}`}
											        type="text"
												    placeholder={`${errors.piva ? errors.piva.message : "PIVA/CF *"}`}
                                                    {...register("piva", {
                                                        required: "PIVA/CF richiesta!"
                                                    })}
												 />
											</div>
											<div className="col-md-6">
												<label><i className="fa fa-phone"></i></label>
												<input className={`${errors.phone ? "error" : ""}`}
											        type="text"
												    placeholder={`${errors.phone ? errors.phone.message : "Telefono *"}`}
                                                    {...register("phone", {
                                                        required: "Telefono richiesto!"
                                                    })}
												 />
											</div>
											<div className="col-md-12">
												<label><i className="fa fa-map-marker"></i>  </label>
												<input className={`${errors.address ? "error" : ""}`}
											        type="text"
												    placeholder={`${errors.address ? errors.address.message : "Indirizzo *"}`}
                                                    {...register("address", {
                                                        required: "Indirizzo richiesto!"
                                                    })}
												 />
											</div>
											<div className="col-md-6">
												<label><i className="fa fa-globe"></i>  </label>
												<input className={`${errors.comune ? "error" : ""}`}
											        type="text"
												    placeholder={`${errors.comune ? errors.comune.message : "Comune *"}`}
                                                    {...register("comune", {
                                                        required: "Comune richiesto!"
                                                    })}
												 />
											</div>
											<div className="col-md-3">
												<label><i className="fa fa-globe"></i>  </label>
												<input className={`${errors.provincia ? "error" : ""}`}
											        type="text"
												    placeholder={`${errors.provincia ? errors.provincia.message : "Provincia *"}`}
                                                    {...register("provincia", {
                                                        required: "Provincia richiesta!",
                                                        pattern: {
                                                            value: /^[a-zA-Z]+$/,
                                                            message: "Provincia non valida!"
                                                        },
                                                        maxLength: {
                                                            value: 2,
                                                            message: "Inserire la sigla!",
                                                        },
                                                        minLength: {
                                                            value: 2,
                                                            message: "Inserire la sigla!",
                                                        }
                                                    })}
												 />
											</div>
											<div className="col-md-3">
												<label><i className="fa fa-thumb-tack "></i>  </label>
												<input className={`${errors.cap ? "error" : ""}`}
											        type="text"
												    placeholder={`${errors.cap ? errors.cap.message : "Cap *"}`}
                                                    {...register("cap", {
                                                        required: "Cap richiesto!"
                                                    })}
												 />
											</div>
											<div className="col-md-12">
												<label><i className="fa fa-info "></i>  </label>
												<textarea placeholder="Descrizione..."
                                                    {...register("description")}
												 />
												<div className="filter-tags">
													<input id="privacy" type="checkbox" name="privacy" required />
													<label htmlFor="privacy">Inviando la richiesta accetti la memorizzazione e la gestione dei tuoi dati da questo sito web. Ai sensi dell’art. 13 del D. Lgs. 196/2003 e dell’art. 13 del Regolamento (UE) 2016/679.</label>
												</div>
											</div>
											<div className="clearfix"/>
										</fieldset>
										<button className="btn  big-btn  color-bg flat-btn">Invia<i className="fa fa-angle-right"></i></button>
									</form>
								</div>
							</div>
							<div className="col-md-4">
								<Faq />
							</div>
						</div>
					</div>
				</section>
				<Questions />
			</div>
		</div>
	);
}