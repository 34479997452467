import axios from "axios";

const API_URL = process.env.REACT_APP_API_ENDPOINT;
let search = window.location.search;
let pathname = window.location.pathname;
let params = new URLSearchParams(search);
let token = params.get('token');

class RegistrationService {
    save(registration) {
        return axios.post(`${API_URL}/register/save`, registration);
    }
    confirm() {
        return axios.get(`${API_URL}/register${pathname}`, {
            params: {
                token: token,
                }
            });
    }
}

export default new RegistrationService();