import "./Contacts.css";
import Features from "../features/Features";
import avatarContacts from '../images/contatti_moltoutile_avatar.png';
import React, { useState } from 'react'
import contactService from '../../../../services/ContactService';
import { useForm } from "react-hook-form";

export default function Contacts() {
    const { handleSubmit, register, reset, formState: { errors } } = useForm();
    const [msg, setMsg] = useState("");
    const [errorMsg, setErrorMsg] = useState(false);
    const ContactInfo = (data) => {
        console.log(data);
        contactService.save(data)
            .then((res) => {
                console.log("Email send");
                setMsg("Email inviata correttamente, grazie!");
                reset();
            }).catch((error) => {
                setMsg("Invio email fallito, riprovare!");
                setErrorMsg(true);
                console.log(error);
            });
    }
    return (
        <div id="wrapper">
            <div className="content">
                <section className="parallax-section small-par background-secondary">
                    <div className="shapes-bg-big"></div>
                    <div className="container">
                        <div className="section-title center-align nopadding">
                            <h2><span>moltoutile.com</span></h2>
                            <div className="contacts-avatar"><img src={avatarContacts} alt="Molto utile contatti"/></div>
                        </div>
                    </div>
                    <div className="header-sec-link">
                        <div className="container"><a href="#secForm" className="custom-scroll-link">Contattaci</a></div>
                    </div>
                </section>
                <section className="hero-section-light background-dark">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="list-single-main-item fl-wrap">
                                    <div className="list-single-main-item-title fl-wrap">
                                        <h3>Non aspettare, <span> contattaci!</span></h3>
                                    </div>
                                    <div id="secForm">
                                        {msg && <div className={`message ${errorMsg ? "error" : ""}`}>{msg}</div>}
                                        <form className="custom-form" onSubmit={handleSubmit(ContactInfo)}>
                                            <fieldset>
                                                <div className="col-md-6">
												    <label><i className="fa fa-user-o"></i></label>
												    <input className={`${errors.name ? "error" : ""}`}
											            type="text"
												        placeholder={`${errors.name ? errors.name.message : "Nome Completo *"}`}
                                                        {...register("name", {
                                                            required: "Nome Completo richiesto!"
                                                        })}
												    />
                                                </div>
                                                <div className="col-md-6">
                                                    <label><i className="fa fa-envelope-o"></i> </label>
                                                    <input className={`${errors.email ? "error" : ""}`}
                                                        type="text"
                                                        placeholder={`${errors.email ? errors.email.message : "Email *"}`}
                                                        {...register("email", {
                                                            required: "Email richiesta!",
                                                            pattern: {
                                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                                message: "Email non valida!"
                                                            }
                                                        })}
                                                     />
                                                </div>
                                                <div className="col-md-12">
                                                    <label><i className="fa fa-info "></i>  </label>
                                                    <textarea className={`${errors.description ? "error" : ""}`}
                                                        placeholder={`${errors.description ? errors.description.message : "Descrizione*..."}`}
                                                        {...register("description", {
                                                            required: "Descrizione richiesta!"
                                                        })}
                                                     />
                                                    <div className="filter-tags">
                                                        <input id="privacy" type="checkbox" name="privacy" required/>
                                                        <label htmlFor="privacy">Inviando la richiesta accetti la memorizzazione e la gestione dei tuoi dati da questo sito web. Ai sensi dell’art. 13 del D. Lgs. 196/2003 e dell’art. 13 del Regolamento (UE) 2016/679.</label>
                                                    </div>
                                                </div>
                                                <div className="clearfix"></div>
                                            </fieldset>
                                            <button className="btn  big-btn  color-bg flat-btn">Invia<i className="fa fa-angle-right"></i></button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="fl-wrap">
                                    <div className="box-widget-item fl-wrap">
                                        <div className="box-widget-item-header">
                                            <h3>Informazioni : </h3>
                                        </div>
                                        <div className="box-widget">
                                            <div className="box-widget-content">
                                                <div className="list-author-widget-contacts list-item-widget-contacts">
                                                    <ul>
                                                        <li><span><i className="fa fa-envelope-o"></i> Email :</span> <a href="mailto:info@moltoutile.com">info@moltoutile.com</a></li>
                                                        <li><span><i className="fa fa-globe"></i> Website :</span> <a href="/">moltoutile.com</a></li>
                                                    </ul>
                                                </div>
                                                <div className="list-widget-social">
                                                    <ul>
                                                        <li><a href="#" target="_blank" ><i className="fa fa-facebook"></i></a></li>
                                                        <li><a href="#" target="_blank"><i className="fa fa-twitter"></i></a></li>
                                                        <li><a href="#" target="_blank" ><i className="fa fa-vk"></i></a></li>
                                                        <li><a href="#" target="_blank" ><i className="fa fa-whatsapp"></i></a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <span className="fw-separator"></span>
                            <Features />
                        </div>
                    </div>
                </section>
            </div>
        </div>
	    );
}