import "./Faq.css";
import React, { useState } from 'react';

export default function FaqRegistration() {
	const [active, setActive] = useState("");
 
	return (
		<div id="wrapper">
			<div className="content">
            	<section className="hero-section-light background-dark">
                	<div className="container">
                    	<div className="section-title">
                        	<h2>FAQ</h2>
                            <div className="section-subtitle">Domande frequenti</div>
                            <span className="section-separator"></span>
                            <p>Consulta le migliori indicazioni e consigli da parte dei nostri partner e amici.</p>
                        </div>	
                        <div className="row topbot50padding">
                        	<div className="col-md-6">
								<div className="accordion">
				                	<a onClick={() => {setActive("first");}} className={`toggle ${active === "first" ? "act-accordion" : ""}`}>Come funziona il vostro servizio di ricerca?  <i className="fa fa-angle-down"></i></a>
				                    <div className= {`accordion-inner ${active === "first" ? 'visible' : ''}`}>
				                        <p>Il nostro servizio di ricerca consente agli utenti di cercare professionisti in base alle loro competenze, esperienze e posizioni geografiche. Basta inserire le parole chiave pertinenti nel campo di ricerca e visualizzare i risultati corrispondenti.</p>
				                    </div>
				                    <a onClick={() => {setActive("second");}} className={`toggle ${active === "second" ? "act-accordion" : ""}`} >Quali tipi di professionisti posso trovare tramite il vostro portale?<i className="fa fa-angle-down"></i></a>
				                    <div className= {`accordion-inner ${active === "second" ? 'visible' : ''}`}>
				                        <p>Sul nostro portale è possibile trovare una vasta gamma di amministratori di condominio.</p>
				                    </div>
				                    <a onClick={() => {setActive("three");}} className={`toggle ${active === "three" ? "act-accordion" : ""}`}>Qual è il costo per utilizzare il servizio di ricerca?<i className="fa fa-angle-down"></i></a>
				                    <div className= {`accordion-inner ${active === "three" ? 'visible' : ''}`}>
				                        <p>Il nostro servizio di ricerca è completamente gratuito per gli utenti. Non applichiamo alcun costo per la ricerca o l'accesso ai profili dei professionisti.</p>
				                    </div>
				                    <a onClick={() => {setActive("four");}} className={`toggle ${active === "four" ? "act-accordion" : ""}`}>Come posso migliorare la visibilità del mio profilo professionale sul sito?<i className="fa fa-angle-down"></i></a>
				                    <div className= {`accordion-inner ${active === "four" ? 'visible' : ''}`}>
				                        <p>Per migliorare la visibilità del tuo profilo professionale, assicurati di inserire informazioni dettagliate e aggiornate sulle tue competenze, esperienze e progetti passati. È attiva la versione a pagamento per i professionisti, che offre una visibilità.</p>
				                    </div>
				                    <a onClick={() => {setActive("five");}} className={`toggle ${active === "five" ? "act-accordion" : ""}`}>Posso contattare direttamente i professionisti trovati tramite il sito?<i className="fa fa-angle-down"></i></a>
				                    <div className= {`accordion-inner ${active === "five" ? 'visible' : ''}`}>
				                        <p>Sì, è possibile contattare direttamente i professionisti ricercati tramite il nostro portale. Ogni professionista ha un modo per essere contattato tramite il suo profilo, che può includere un modulo di contatto, un indirizzo email o un numero di telefono. </p>
				                    </div>				
								</div>                        	
                        	</div>
                        	<div className="col-md-6">
								<div className="accordion">
				                	<a onClick={() => {setActive("six");}} className={`toggle ${active === "six" ? "act-accordion" : ""}`}>Come posso filtrare i risultati della ricerca per trovare professionisti specifici?<i className="fa fa-angle-down"></i></a>
				                    <div className= {`accordion-inner ${active === "six" ? 'visible' : ''}`}>
				                        <p>Puoi filtrare i risultati della ricerca utilizzando vari criteri, tra cui competenze, esperienze, posizione geografica e altro ancora. Basta utilizzare gli strumenti di filtro disponibili sul nostro portale per raffinare la tua ricerca.</p>
				                    </div>
				                    <a onClick={() => {setActive("seven");}} className={`toggle ${active === "seven" ? "act-accordion" : ""}`} >Il vostro sito garantisce la qualità dei professionisti elencati?<i className="fa fa-angle-down"></i></a>
				                    <div className= {`accordion-inner ${active === "seven" ? 'visible' : ''}`}>
				                        <p>Non possiamo garantire la qualità di ogni professionista elencato sul nostro sito, ma facciamo del nostro meglio per verificare le informazioni fornite e monitorare i feedback degli utenti per garantire un'esperienza positiva per tutti.</p>
				                    </div>
				                    <a onClick={() => {setActive("eight");}} className={`toggle ${active === "eight" ? "act-accordion" : ""}`}>Cosa devo fare se trovo un professionista che non corrisponde alle mie aspettative?<i className="fa fa-angle-down"></i></a>
				                    <div className= {`accordion-inner ${active === "eight" ? 'visible' : ''}`}>
				                        <p>Se trovi un professionista che non corrisponde alle tue aspettative, ti consigliamo di modificare i criteri di ricerca e successivamente ci trattare i professionisti per valutare.</p>
				                    </div>
				                    <a onClick={() => {setActive("nine");}} className={`toggle ${active === "nine" ? "act-accordion" : ""}`}>Come posso segnalare un problema tecnico o fornire un feedback sul servizio di ricerca?<i className="fa fa-angle-down"></i></a>
				                    <div className= {`accordion-inner ${active === "nine" ? 'visible' : ''}`}>
				                        <p>Puoi segnalare problemi tecnici o fornire feedback sul nostro servizio di ricerca contattando il nostro team di supporto tramite l'apposito modulo di contatto sul nostro sito. Siamo sempre interessati a migliorare la nostra piattaforma e apprezziamo i tuoi commenti.</p>
				                    </div>		
								</div>                        	
                        	</div>                        	
                        </div>
                	</div>
            	</section>                				
	   		</div>
		</div>			
	);
} 