import { Link  } from 'react-router-dom';

export default function TokenOK() {
  	return (
            <div className="section-title">
                <h2>Registrazione Confermata</h2>
                <span className="section-separator"></span>
                <p className="bot50padding">Il tuo indirizzo email è stato verificato.</p>
                <Link  to="/" className="btn  big-btn  color-bg flat-btn">Home
                    <i className ="fa fa-angle-right"></i>
                </Link>
            </div>
	);
}