import { Link  } from 'react-router-dom';

export default function TokenExpired() {
  	return (
            <div className="section-title">
                <h2>Registrazione non confermata</h2>
                <span className="section-separator"></span>
                <p className="bot50padding">Il tuo indirizzo email non è stato verificato.</p>
                <Link  to="/contacts" className="btn  big-btn  color-bg flat-btn">Contattaci
                    <i className ="fa fa-angle-right"></i>
                </Link>
            </div>
	);
}