import "./Features.css";

export default function Features() {
	return ( 
   	<div className="features-box-container fl-wrap row">
    	<div className="features-box col-md-4">
            <div className="time-line-icon">
                <i className="fa fa-medkit"></i>
            </div>
            <h3>Supporto</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas in pulvinar neque. Nulla finibus lobortis pulvinar. Donec a consectetur nulla.  </p>
        </div>
    	<div className="features-box col-md-4">
            <div className="time-line-icon">
                <i className="fa fa-cogs"></i>
            </div>
            <h3>Gestione Profilo</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas in pulvinar neque. Nulla finibus lobortis pulvinar. Donec a consectetur nulla.  </p>
        </div>
    	<div className="features-box col-md-4">
            <div className="time-line-icon">
                <i className="fa fa-television"></i>
            </div>
            <h3>Mobile Friendly</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas in pulvinar neque. Nulla finibus lobortis pulvinar. Donec a consectetur nulla.  </p>
        </div>                
	</div>
	);
}