import "./Questions.css";
import { Link  } from 'react-router-dom'; 

export default function Questions() {
	const scrollToTop = () => {
	    window.scrollTo(0, 0)
	}
	return (
		<section className ="background-primary hero-section-light">
		    <div className ="cirle-bg">
		        <div className ="bg"></div>
		    </div>
		    <div className ="container">
		        <div className ="join-wrap fl-wrap">
		            <div className ="row">
		                <div className ="col-md-8 col-xs-12">
		                    <h3>Hai delle domande ?</h3>
		                    <p>Sono a disposizioni professionisti per forniti informazioni su qualunque dubbio.</p>
		                </div>
		                <div onClick={scrollToTop} className ="col-md-2 col-xs-6">
		                	<Link  to="/contacts" className="join-wrap-btn">  Contattaci 
		                		<i className ="fa fa-envelope-o"></i>
		               		</Link> 
		                </div>
		                <div onClick={scrollToTop} className ="col-md-2 col-xs-6">
		                	<Link  to="/faq" className="join-wrap-btn">  Faq 
		                		<i className ="fa fa-question"></i>
		               		</Link> 
		                </div>
		            </div>
		        </div>
		    </div>
		</section>		
	);
} 