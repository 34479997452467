import "./Faq.css";
import React, { useState } from 'react';

export default function FaqRegistration() {
	const [active, setActive] = useState("");
 
	return (
		<div className="fl-wrap">
			<div className="box-widget-item fl-wrap">
				<div className="accordion">
                	<a onClick={() => {setActive("first");}} className={`toggle ${active === "first" ? "act-accordion" : ""}`}> Perché è richiesto il nome completo del professionista o dell’azienda? <i className="fa fa-angle-down"></i></a>
                    <div className= {`accordion-inner ${active === "first" ? 'visible' : ''}`}>
                        <p>Il nome completo è richiesto per identificare il professionista o l’azienda che si sta registrando sul portale.</p>
                    </div>
                    <a onClick={() => {setActive("second");}} className={`toggle ${active === "second" ? "act-accordion" : ""}`} > Perché è necessaria l’e-mail per la registrazione?  <i className="fa fa-angle-down"></i></a>
                    <div className= {`accordion-inner ${active === "second" ? 'visible' : ''}`}>
                        <p>L’e-mail è richiesta per scopi di comunicazione e verifica dell’account. È anche utile per inviare aggiornamenti e notifiche relative all’account.</p>
                    </div>
                    <a onClick={() => {setActive("three");}} className={`toggle ${active === "three" ? "act-accordion" : ""}`}> Perché è richiesta la partita IVA o il codice fiscale? <i className="fa fa-angle-down"></i></a>
                    <div className= {`accordion-inner ${active === "three" ? 'visible' : ''}`}>
                        <p>La partita IVA o il codice fiscale sono necessari per scopi fiscali e di identificazione dell’azienda o del professionista in modo univoco.</p>
                    </div>
                    <a onClick={() => {setActive("four");}} className={`toggle ${active === "four" ? "act-accordion" : ""}`}> Perché è richiesto il numero di telefono?<i className="fa fa-angle-down"></i></a>
                    <div className= {`accordion-inner ${active === "four" ? 'visible' : ''}`}>
                        <p>Il numero di telefono è richiesto per essere inserito nei risultati della ricerca al fine di essere contattati da potenziali clienti.</p>
                    </div>
                    <a onClick={() => {setActive("five");}} className={`toggle ${active === "five" ? "act-accordion" : ""}`}> Perché è richiesto l’indirizzo? <i className="fa fa-angle-down"></i></a>
                    <div className= {`accordion-inner ${active === "five" ? 'visible' : ''}`}>
                        <p>L’indirizzo è utile per essere inserito nei risultati della ricerca eventualmente per essere raggiunti da potenziali clienti.</p>
                    </div>
                    <a onClick={() => {setActive("six");}} className={`toggle ${active === "six" ? "act-accordion" : ""}`}> Perché è richiesta una breve descrizione dei servizi aziendali? <i className="fa fa-angle-down"></i></a>
                    <div className= {`accordion-inner ${active === "six" ? 'visible' : ''}`}>
                        <p>La breve descrizione dei servizi aziendali aiuta a fornire informazioni di base su ciò che l’azienda o il professionista offre, consentendo agli utenti di comprendere meglio le attività offerte.</p>
                    </div>
				</div>
			</div>
		</div>		
	);
} 