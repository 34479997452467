import axios from "axios";

const API_URL = process.env.REACT_APP_API_ENDPOINT;

class ContactService {
    save(contact) {
        return axios.post(`${API_URL}/contact/save`, contact);
    }
}

export default new ContactService();