import "./Administrator.css";
import Questions from "../questions/Questions";
import icoAziende from '../images/aziende_convezionate.png'
import test from '../images/1.jpg';
import React, { useState } from 'react';

const welcome = 'Ti aiuteremo a trovare quello che cerchi';
const comune = 'Inserisci il tuo comune?';
const cap = 'Per semplicità puoi inserire anche il tuo cap?';

export default function Administrator() {
    const [listCard, setlistCard] = useState(false);
    return (
        <div id="wrapper">
            <div className ="content">
                <section className ="parallax-section">
                    <div className="bg"></div>
                    <div className ="overlay"></div>
                    <div className ="hero-section-wrap fl-wrap">
                        <div className ="container">
                            <div className ="intro-item fl-wrap">
                                <h2>{welcome}</h2>
                            </div>
                            <div className="main-search-input-wrap">
                                <div className="main-search-input fl-wrap">
                                    <div className="main-search-input-item">
                                        <input type="text" placeholder={comune}/>
                                    </div>
                                    <div className="main-search-input-item location" >
                                        <input type="text" placeholder={cap}/>
                                    </div>
                                    <button className="main-search-button">Cerca</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className ="no-pading no-top-padding">
                    <div className="col-list-wrap fh-col-list-wrap left-list">
                        <div className ="container">
                            <div className="row">
                                <div className="col-md-8">
                                    <div className="listsearch-header fl-wrap">
                                        <h3>Risultati ottenuti : <span>2</span></h3>
                                        <div className="listing-view-layout">
                                            <ul>
                                                <li><a className={`grid ${listCard ? "" : "active"}`} onClick={() => {setlistCard(false);}}><i className="fa fa-th-large"></i></a></li>
                                                <li><a className={`list ${listCard ? "active" : ""}`} onClick={() => {setlistCard(true);}}><i className="fa fa-list-ul"></i></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="list-main-wrap fl-wrap card-listing">
                                        <div className={`listing-item ${listCard ? "list-layout" : ""}`}>
                                            <article className="geodir-category-listing fl-wrap">
                                                <div className="geodir-category-img">
                                                    <img src={test} alt=""/>
                                                    <div className="overlay"></div>
                                                </div>
                                                <div className="geodir-category-content fl-wrap">
                                                    <a className="listing-geodir-category" href="#">Conferma Dati</a>
                                                    <div className="listing-avatar"><a href="#"><img src={test} alt=""/></a>
                                                        <span className="avatar-tooltip"><strong>Lisa Smith</strong></span>
                                                    </div>
                                                    <h3><a href="#">Condominio Uno</a></h3>
                                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                                                    <div className="geodir-category-options fl-wrap">
                                                        <div className="geodir-category-location"><a href="#"><i className="fa fa-map-marker" aria-hidden="true"></i> Via Milano 4, Milano(MI) 43125</a></div>
                                                    </div>
                                                </div>
                                            </article>
                                        </div>
                                        <div className={`listing-item ${listCard ? "list-layout" : ""}`}>
                                            <article className="geodir-category-listing fl-wrap">
                                                <div className="geodir-category-img">
                                                    <img src={test} alt=""/>
                                                    <div className="overlay"></div>
                                                </div>
                                                <div className="geodir-category-content fl-wrap">
                                                    <a className="listing-geodir-category check-data" href="#">Dati Confermati</a>
                                                    <div className="listing-avatar"><a href="#"><img src={test} alt=""/></a>
                                                        <span className="avatar-tooltip"><strong>Lisa Smith</strong></span>
                                                    </div>
                                                    <h3><a href="#">Condominio Due</a></h3>
                                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                                                    <div className="geodir-category-options fl-wrap">
                                                        <div className="geodir-category-location"><a href="#"><i className="fa fa-map-marker" aria-hidden="true"></i> Via Milano 4, Milano(MI) 43125</a></div>
                                                    </div>
                                                </div>
                                            </article>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Questions />
            </div>
        </div>
  );
}