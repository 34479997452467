import { Link  } from 'react-router-dom';

export default function TokenNotFound() {
  	return (
            <div className="section-title">
                <h2>Pagina non trovata</h2>
                <span className="section-separator"></span>
                <p className="bot50padding">Siamo spiacenti, ma la Pagina che stavi cercando non è stata trovata.</p>
                <Link  to="/" className="btn  big-btn  color-bg flat-btn">Home
                    <i className ="fa fa-angle-right"></i>
                </Link>
            </div>
	);
}