import "./static/css/styles.css";
import Header from "./components/header/Header";
import Main from "./components/main/Main";
import Footer from "./components/footer/Footer";
import ScrollTop from "./components/scrolltop/ScrollTop";
import LoginForm from "./components/loginform/LoginForm";
import { BrowserRouter as Router} from 'react-router-dom';

export default function App() {
  return (
    <div className="main">
      <Router>
      	<Header /> 
      	<Main />   
      </Router>
      <Footer />
      <LoginForm />
      <ScrollTop />
    </div>
  );
}
