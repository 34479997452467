import "./Home.css";
import "./HomeMobile.css";
import { Link  } from 'react-router-dom';
import Questions from "../questions/Questions";
import icoAmm from '../images/amministratore_condominio.png';
import icoAziende from '../images/aziende_convezionate.png';

const welcome = 'Ricerca l\'amministratore di condominio più adatto a te';
const amministratori = 'Amministratori di condominio';
const aziende = 'Aziende convenzionate';

export default function Home() {
    const scrollToTop = () => {
	    window.scrollTo(0, 0)
	}
    return (
        <div id="wrapper">
            <div className ="content">
                <section className ="parallax-section">
                    <div className="bg"></div>
                    <div className ="overlay"></div>
                    <div className ="hero-section-wrap fl-wrap">
                        <div className ="container">
                            <div className ="intro-item fl-wrap">
                                <h2>{welcome}</h2>
                            </div>
                            <div className ="list-single-facts fl-wrap">
                                <div className ="inline-facts-wrap">
                                    <div onClick={scrollToTop} className ="inline-facts">
                                        <Link  to="/administrator">
                                            <img src={icoAmm} alt=""/>
                                        </Link>
                                        <h6>{amministratori}</h6>
                                    </div>
                                </div>
                                <div className ="inline-facts-wrap">
                                    <div className ="inline-facts">
                                        <a href="#" target="_blank"><img src={icoAziende} alt=""/></a>
                                        <h6>{aziende}</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className ="hero-section-light">
                    <div className ="container">
                        <div className ="section-title nopadding">
                            <h2>Come funziona</h2>
                            <span className ="section-separator"></span>
                        </div>
                        <div className ="process-wrap fl-wrap">
                            <ul>
                                <li>
                                    <div className ="process-item">
                                        <span className ="process-count">01 . </span>
                                        <div className ="time-line-icon"><i className ="fa fa-map-o"></i></div>
                                        <h4>Scegli la sezione di ricerca</h4>
                                        <p>Puoi cercare l'amministratore o il professionista nella tua zona e ....</p>
                                    </div>
                                    <span className ="pr-dec"></span>
                                </li>
                                <li>
                                    <div className ="process-item">
                                        <span className ="process-count">02 .</span>
                                        <div className ="time-line-icon"><i className ="fa fa-envelope-open-o"></i></div>
                                        <h4>Contatta le ricerche di tuo interesse</h4>
                                        <p>Puoi inviare una richiesta di preventivo o informazioni generali.</p>
                                    </div>
                                    <span className ="pr-dec"></span>
                                </li>
                                <li>
                                    <div className ="process-item">
                                        <span className ="process-count">03 .</span>
                                        <div className ="time-line-icon"><i className ="fa fa-hand-peace-o"></i></div>
                                        <h4>Riceverai una risposta</h4>
                                        <p>Sarai contatto da un amministratore o da un professionista il prima possibile.</p>
                                    </div>
                                </li>
                            </ul>
                            <div className ="process-end"><i className ="fa fa-check"></i></div>
                        </div>
                    </div>
                </section>
                <Questions />
            </div>
        </div>
  );
}